import {
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
  FastForward as FastForwardIcon,
  FastRewind as FastRewindIcon,
  ViewCarousel as ViewCarouselIcon,
  ViewColumn as ViewColumnIcon
} from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { GlobalStyle } from "GlobalStyle";
import produce from "immer";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import AlarmAPI from "~/common/apis/AlarmAPI";
import CameraAPI from "~/common/apis/CameraAPI";
import Bar from "~/common/components/Bar";
import { CreateAlarmDialog } from "~/common/components/dialogs/CreateAlarmDialog";
import ExpandedImage from "~/common/components/ExpandedImage";
import FullscreenDialog from "~/common/components/FullscreenDialog";
import Image from "~/common/components/Image";
import Sliders from "~/common/components/Slider";
import Tags from "~/common/components/Tags";
import UTCDatePicker from "~/common/components/UTCDatePicker";
import {
  getSelectedAlarm,
  selectIsEditing
} from "~/common/selectors/AlarmSelector";
import { getSelectedCamera } from "~/common/selectors/CameraSelector";
import { selectDistanceSegmentsState } from "~/common/selectors/distance-segments-selector";
import { PoiDistanceSegmentsActions } from "~/common/store/slices/poi-distance-segments";
import {
  calculateOverallConf,
  getValueFromSessionStorage
} from "~/common/utils/dataUtils";
import { handleImgUrl } from "~/common/utils/functionUtils";
import {
  DistanceMapper,
  handleSegmentingPoiChange
} from "~/containers/DistanceMapper";
import LeakSourceTool from "~/containers/LeakSourceLocationTool";
import { useAlarmCreation, useOrganization } from "~/hooks";

// TODO:(s.w) this component is way too big and needs splitting
const ImageList = ({
  addEditAlarm,
  scanIndex,
  setScanIndex,
  scanResults,
  setScanResults,
  poiKeys,
  events,
  eventConf,
  endDate,
  handleDateChange,
  resetAlarmCreationSession,
  selectedPoi,
  setSelectedPoi,
  deviceId,
  rawScanResults = []
}) => {
  const dispatch = useDispatch();

  const [humanActivityFocus, setHumanActivityFocus] = useState(false);
  const [tagsFocus, setTagsFocus] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { classes: globalStyle } = GlobalStyle();
  const [leakDistance, setLeakDistance] = useState(null);
  const [selectedSource, setSelectedSource] = useState(null);

  const fullScreenImgRef = useRef(null);
  const { selectedOrg } = useOrganization();
  const selectedCamera = useSelector(getSelectedCamera, shallowEqual);
  const poiDistanceSegmentState = useSelector(
    selectDistanceSegmentsState,
    shallowEqual
  );
  const [showDistanceSegments, setShowDistanceSegments] = useState(true);

  const selectedAlarm = useSelector(getSelectedAlarm, shallowEqual);
  const isAlarmEditing = useSelector(selectIsEditing);

  const [imgType, setImgType] = useState(
    getValueFromSessionStorage("imageType", "colDen")
  );
  const [displayType, setDisplayType] = useState("carousel");
  const [openDistanceMapper, setOpenDistanceMapper] = useState(false);

  const prvImg = scanIndex + 1;
  const nextImg = scanIndex - 1;
  const currImg = scanIndex;
  const [fullScreenImgUrl, setFullScreenImgUrl] = useState();
  const [fullScreenImgType, setFullScreenImgType] = useState("colDen");
  const [fullScreenImg, setFullScreenImg] = useState(false);
  const [currentImageConfidence, setCurrentImageConfidence] = useState(0);
  const showDetectionBoxes =
    sessionStorage.getItem("showDetectionBoxes") === "false" ? false : true;

  const {
    alarmEnd,
    alarmStart,
    creatingAlarm,
    showLeakSourceTool,
    handleAlarmCancel,
    handleCreatingAlarm,
    handleExtendAlarmPeriod,
    handleNewAlarm,
    isInAlarmDateRange,
    setAlarmEnd,
    alarmLeakSources,
    setAlarmLeakSources,
    setAlarmStart,
    setShowLeakSourceTool,
    setDistanceSegment
  } = useAlarmCreation({
    addEditAlarm,
    eventConf,
    setScanResults,
    selectedPoi
  });

  const quantificationProps = {
    alarmLeakSources,
    leakDistance,
    setLeakDistance,
    selectedSource,
    setSelectedSource,
    showDistanceSegments,
    setShowDistanceSegments,
    setDistanceSegment
  };

  const selectedScan = useMemo(() => {
    return scanResults[scanIndex];
  }, [scanIndex, scanResults]);

  const setScanTags = tags =>
    setScanResults(results =>
      produce(results, draft => {
        const match = draft.find(scan => scan.id === selectedScan.id);
        match.tags = tags;
      })
    );

  const loadAlarms = (frameId, leakRois) =>
    setScanResults(
      produce(draft => {
        const match = draft.find(scan => scan.id === frameId);
        match.leakSources = [{ ...leakRois }];
      })
    );

  const [tags, setTags] = useState(selectedScan?.tags || []);
  const [alarms, setAlarms] = useState([{ start: 0, end: 0 }]);

  useEffect(() => {
    setTags(selectedScan?.tags || []);
  }, [selectedScan]);

  const updateTags = updatedTags => {
    setTags(updatedTags);
    CameraAPI.updateTags(deviceId, selectedScan.id, updatedTags)
      .then(response => setScanTags(response.data))
      .catch(error => {
        console.error(error);
        enqueueSnackbar("Tag update failed!", {
          variant: "error"
        });
        setTags(selectedScan?.tags || []);
      });
  };

  useEffect(() => {
    setCurrentImageConfidence(calculateOverallConf(selectedScan));
  }, [selectedScan]);

  const handleFullscreenImage = (
    type = imgType,
    fullscreenMode = true,
    leakSourceToolMode = false
  ) => {
    const url =
      type === "rgb" ? selectedScan.rgb : handleImgUrl(selectedScan.jpg, type);
    setFullScreenImgUrl(url);
    setFullScreenImgType(type);

    if (fullscreenMode) {
      setFullScreenImg(true);
    } else if (leakSourceToolMode) {
      const leakSourceToolUrl = handleImgUrl(selectedScan.jpg, "colOD");
      setFullScreenImgUrl(leakSourceToolUrl);
      setShowLeakSourceTool(true);
    }
  };

  const handleOpenDistanceMapper = () => {
    const url = handleImgUrl(selectedScan.jpg, "colDes");
    setFullScreenImgUrl(url);
    setOpenDistanceMapper(true);
  };

  // Change between images while in full screen or Leak soure tool mode
  useEffect(() => {
    if (fullScreenImg) {
      handleFullscreenImage(fullScreenImgType);
    } else if (showLeakSourceTool) {
      handleFullscreenImage(fullScreenImgType, false, true);
    }
  }, [scanIndex]);

  const handleImgTypeButton = type => {
    setImgType(type);
    sessionStorage.setItem("imageType", type);
  };

  const handleDisplayType = (event, value) => {
    if (value) {
      setDisplayType(value);
    }
  };

  // watch for change in org/endDate/camera then reset alarm settings
  useEffect(() => {
    resetAlarmCreationSession();
  }, [selectedOrg, endDate, selectedCamera]);

  // Watch for date change and reset selectedPoi and slider to 'first' image
  useEffect(() => {
    sessionStorage.removeItem("scanIndex");
  }, [endDate]);

  // Watch for scanIndex change and set session storage accordingly
  useEffect(() => {
    // Save last selected frame
    const lastSelectedFrame = scanResults[sessionStorage.getItem("scanIndex")];
    lastSelectedFrame &&
      sessionStorage.setItem(
        "lastSelectedFrame",
        JSON.stringify(lastSelectedFrame)
      );

    // Save new index
    sessionStorage.setItem("scanIndex", scanIndex);
  }, [scanIndex]);

  useEffect(() => {
    if (isAlarmEditing) {
      const { leakRois } = selectedAlarm ?? [];
      leakRois?.map(item => {
        const leakSources = {
          id: item.frameId,
          coords: item.coords
        };
        loadAlarms(leakSources.id, leakSources);
      });
    }
  }, [selectedAlarm]);

  const handleRightArrow = () => {
    setScanIndex(index => Math.max(index - 1, 0));
  };

  const handleLeftArrow = () => {
    setScanIndex(index => Math.min(index + 1, scanResults.length - 1));
  };

  const handlePrevFrameWithConfidence = () => {
    const index = scanResults.findIndex(
      (item, index) => item.overallConf > 0 && index > scanIndex
    );
    if (index > 0) setScanIndex(index);
  };

  const handleNextFrameWithConfidence = () => {
    const index = scanResults.findLastIndex(
      (item, index) => item.overallConf > 0 && index < scanIndex
    );
    if (index > 0) setScanIndex(index);
  };

  const handleMouseWheel = e => {
    if (Math.sign(e.deltaY) === 1) {
      // Scroll up
      handleRightArrow();
    } else {
      // Scroll down
      handleLeftArrow();
    }
  };

  // Preload image after next
  useEffect(() => {
    const url = handleImgUrl(scanResults[scanIndex - 2]?.jpg, imgType);
    document.createElement("img").src = url;
  }, [scanIndex, imgType]);

  // Navigate between images with keyboard arrows
  useEffect(() => {
    document.onkeydown = e => {
      const { key } = e;
      if (
        !e.ctrlKey &&
        key === "ArrowLeft" &&
        !humanActivityFocus &&
        !tagsFocus
      ) {
        handleLeftArrow();
      } else if (
        !e.ctrlKey &&
        key === "ArrowRight" &&
        !humanActivityFocus &&
        !tagsFocus
      ) {
        handleRightArrow();
      }
    };
  }, [scanResults.length, humanActivityFocus, tagsFocus]);

  const findEvent = scanIndex => {
    for (let i = 0; i < events.length; i++) {
      if (
        scanResults[scanIndex]?.createdOn >= events[i].start &&
        scanResults[scanIndex]?.createdOn <= events[i].end
      ) {
        return i + 1; // Start event numbering at 1
      }
    }
    return null; // If no event was found
  };

  const handleAlarmStart = () => {
    setAlarmStart(selectedScan?.createdOn);
  };

  const handleAlarmEnd = () => {
    setAlarmEnd(selectedScan?.createdOn);
  };

  useEffect(() => {
    if (creatingAlarm) {
      sessionStorage.setItem("creatingAlarm", "true");
      sessionStorage.setItem("alarmStart", alarmStart);
      sessionStorage.setItem("alarmEnd", alarmEnd);
    } else {
      resetAlarmCreationSession();
    }
  }, [creatingAlarm, alarmStart, alarmEnd]);

  // Keyboard shortcut for alarm creation
  const createAlarmShortcut = e => {
    if (e.ctrlKey && e.key.toUpperCase() === "Z") {
      handleCreatingAlarm(scanResults[scanIndex]?.createdOn);
    }
  };

  useEffect(() => {
    if (!creatingAlarm) {
      window.addEventListener("keydown", createAlarmShortcut);
    }

    return () => window.removeEventListener("keydown", createAlarmShortcut);
  }, [displayType, creatingAlarm, scanIndex, scanResults]);

  // Close Alarm dialog and reset alarm seession on poi change to avoid time conflict/issue
  useEffect(() => {
    !isAlarmEditing && handleAlarmCancel();
    resetAlarmCreationSession();
  }, [selectedPoi]);

  useEffect(() => {
    const getAlarms = async () => {
      let dateArr = scanResults[0].createdOn.split("T")[0].split("-");
      let year = dateArr[0];
      let month = dateArr[1];
      if (dateArr[1].split("")[0] === "0") {
        month = dateArr[1].split("")[1];
      }
      // Use params if available otherwise use last selected device
      const defaultDeviceId = deviceId || selectedCamera?.deviceId;

      await AlarmAPI.getAlarmsPerMonth(defaultDeviceId, month, year)
        .then(res => {
          setAlarms(res.data);
        })
        .catch(error => {
          console.log("Could not retrieve alarms:", error);
        });
    };

    getAlarms();
  }, []);

  useEffect(() => {
    const selectedDeviceId = deviceId || selectedCamera?.deviceId;
    selectedDeviceId &&
      dispatch(
        PoiDistanceSegmentsActions.getPoiDistanceSegments({
          organizationId: selectedOrg?.id,
          deviceId: selectedDeviceId
        })
      );
  }, [selectedCamera, selectedOrg]);

  const imgCommonProps = {
    selected: false,
    imgType,
    handleFullscreenImage,
    creatingAlarm,
    handleCreatingAlarm,
    setScanResults,
    selectedPoi,
    handleOpenDistanceMapper
  };

  const saveButtonRef = useRef(null);

  const handleKeyPress = useCallback(
    event => {
      if (event.altKey === true) {
        switch (event.code) {
          case "KeyZ": {
            // Alt + z: Open alarm creation dialog
            handleCreatingAlarm(selectedScan?.createdOn);
            break;
          }
          case "KeyX": {
            // Alt + x: Set alarm start time
            handleAlarmStart();
            break;
          }
          case "KeyC": {
            // Alt + c: Set alarm end time
            handleAlarmEnd();
            break;
          }
          case "KeyV": {
            // Alt + v: Save new alarm
            saveButtonRef.current.click();
            break;
          }
          case "KeyA": {
            // Alt + a: Switch to column density images
            handleImgTypeButton("colDen");
            break;
          }
          case "KeyS": {
            // Alt + s: Switch to colorized OD images
            handleImgTypeButton("colOD");
            break;
          }
          case "KeyW": {
            // Alt + w: Switch to 4 image mode
            handleDisplayType(null, "image");
            break;
          }
          case "KeyQ": {
            // Alt + q: Switch to carousel images mode
            handleDisplayType(null, "carousel");
            break;
          }
          default:
            break;
        }
      }
    },
    [selectedScan]
  );

  // for MAC users: ctrl + command + left/right key
  // for Windows users: ctrl + left/right key
  const handleKeyDown = useCallback(
    ({ key, ctrlKey }) => {
      if (ctrlKey) {
        const keyActionMap = {
          ArrowLeft: handlePrevFrameWithConfidence,
          ArrowRight: handleNextFrameWithConfidence
        };

        keyActionMap[key]?.();
      }
    },
    [selectedScan]
  );

  const handleCloseAlarmDialog = () => {
    dispatch({ type: "SET_IS_EDITING", isEditing: false });
    handleAlarmCancel();
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyPress]);

  return (
    <div className={globalStyle.legacyImgDiv}>
      <FullscreenDialog
        fullScreenImg={fullScreenImg}
        setFullScreenImg={setFullScreenImg}
        selectedScan={selectedScan}
        fullScreenImgRef={fullScreenImgRef}
        fullScreenImgUrl={fullScreenImgUrl}
        fullScreenImgType={fullScreenImgType}
        showDetectionBoxes={showDetectionBoxes}
        setScanResults={setScanResults}
        scanIndex={scanIndex}
        scanResults={scanResults}
        handleLeftArrow={handleLeftArrow}
        handleRightArrow={handleRightArrow}
        setCurrentImageConfidence={setCurrentImageConfidence}
      />

      <div className={globalStyle.legacyImgContainer}>
        <div style={{ width: "100%" }}>
          <div className={globalStyle.legacyImgSubContainer}>
            <div>
              <UTCDatePicker date={endDate} onChange={handleDateChange} />
              <Bar
                displayType={displayType}
                handleImgTypeButton={handleImgTypeButton}
                imgType={imgType}
                selectedScan={selectedScan}
                creatingAlarm={creatingAlarm}
                handleCreatingAlarm={handleCreatingAlarm}
                selectedPoi={selectedPoi}
                setSelectedPoi={setSelectedPoi}
                poiKeys={poiKeys}
                setScanIndex={setScanIndex}
                currentImageConfidence={currentImageConfidence}
                tags={tags}
                updateTags={updateTags}
                setTagsFocus={setTagsFocus}
              />
            </div>
            <ToggleButtonGroup
              className={globalStyle.legacyToggleBtn}
              value={displayType}
              exclusive
              onChange={handleDisplayType}
            >
              <ToggleButton value={"image"}>
                <Tooltip title="Ctrl + W" placement="bottom" arrow>
                  <ViewColumnIcon />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value={"carousel"}>
                <Tooltip title="Ctrl + Q" placement="bottom" arrow>
                  <ViewCarouselIcon />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          <div className={globalStyle.legacyBtn}>
            <IconButton
              disabled={scanIndex === scanResults.length - 1}
              onClick={handlePrevFrameWithConfidence}
              aria-label="left"
              size="large"
            >
              {navigator.appVersion.indexOf("Mac") !== -1 ? (
                <Tooltip
                  title="Ctrl + ⌘ + Arrow Left Key"
                  placement="bottom"
                  arrow
                >
                  <FastRewindIcon />
                </Tooltip>
              ) : (
                <Tooltip title="Ctrl + Arrow Left Key" placement="bottom" arrow>
                  <FastRewindIcon />
                </Tooltip>
              )}
            </IconButton>
            <IconButton
              disabled={scanIndex === scanResults.length - 1}
              onClick={handleLeftArrow}
              aria-label="left"
              size="large"
            >
              <Tooltip title="Arrow Left Key" placement="bottom" arrow>
                <ArrowLeftIcon />
              </Tooltip>
            </IconButton>
            {displayType === "carousel" && (
              <>
                <div className={globalStyle.legacyCarousel}>
                  {scanIndex < scanResults.length - 1 ? (
                    <Image
                      {...imgCommonProps}
                      scan={scanResults[prvImg]}
                      event={findEvent(prvImg)}
                      creatingAlarm={creatingAlarm}
                    />
                  ) : (
                    <div className={globalStyle.legacyCarouselDiv} />
                  )}
                  <Image
                    {...imgCommonProps}
                    selected={true}
                    scan={selectedScan}
                    event={findEvent(currImg)}
                    setCurrentImageConfidence={setCurrentImageConfidence}
                  />

                  {scanIndex > 0 ? (
                    <Image
                      {...imgCommonProps}
                      scan={scanResults[nextImg]}
                      event={findEvent(nextImg)}
                    />
                  ) : (
                    <div className={globalStyle.legacyCarouselDiv} />
                  )}
                </div>
              </>
            )}
            {displayType === "image" && (
              <div style={{ height: "100%", width: "95%" }}>
                <ExpandedImage
                  scan={selectedScan}
                  event={findEvent(scanIndex)}
                  handleFullscreenImage={handleFullscreenImage}
                  setScanResults={setScanResults}
                  setCurrentImageConfidence={setCurrentImageConfidence}
                />
              </div>
            )}

            <IconButton
              disabled={scanIndex === 0}
              onClick={handleRightArrow}
              aria-label="right"
              size="large"
            >
              <Tooltip title="Arrow Right Key" placement="bottom" arrow>
                <ArrowRightIcon />
              </Tooltip>
            </IconButton>

            <IconButton
              disabled={scanIndex === 0}
              onClick={handleNextFrameWithConfidence}
              aria-label="right"
              size="large"
            >
              {navigator.appVersion.indexOf("Mac") !== -1 ? (
                <Tooltip
                  title="Ctrl + ⌘ + Arrow Right Key"
                  placement="bottom"
                  arrow
                >
                  <FastForwardIcon />
                </Tooltip>
              ) : (
                <Tooltip
                  title="Ctrl + Arrow Right Key"
                  placement="bottom"
                  arrow
                >
                  <FastForwardIcon />
                </Tooltip>
              )}
            </IconButton>
          </div>
          <div
            style={{
              top: "25px",
              right: "175px",
              position: "absolute",
              border: "1px solid grey",
              borderRadius: "10px",
              width: "150px",
              height: "100px",
              padding: "5px",
              overflow: "auto"
            }}
          >
            {tags.length === 0 ? (
              <div>No tags available</div>
            ) : (
              <Tags tags={tags} updateTags={updateTags} />
            )}
          </div>
          <Sliders
            alarms={alarms}
            scanIndex={scanIndex}
            setScanIndex={setScanIndex}
            selectedScan={selectedScan}
            scanResults={scanResults}
            findEvent={findEvent}
            onWheel={handleMouseWheel}
            currentImageConfidence={currentImageConfidence}
          />

          <LeakSourceTool
            showLeakSourceTool={showLeakSourceTool}
            setShowLeakSourceTool={setShowLeakSourceTool}
            selectedScan={selectedScan}
            fullScreenImgUrl={fullScreenImgUrl}
            scanIndex={scanIndex}
            scanResults={scanResults}
            setScanResults={setScanResults}
            handleLeftArrow={handleLeftArrow}
            handleRightArrow={handleRightArrow}
            handleExtendAlarmPeriod={handleExtendAlarmPeriod}
            isInAlarmDateRange={isInAlarmDateRange}
            alarmLeakSources={alarmLeakSources}
            setAlarmLeakSources={setAlarmLeakSources}
            selectedPoi={selectedPoi}
            quantificationProps={quantificationProps}
          />

          <DistanceMapper
            handleSegmentingPoiChange={poi =>
              handleSegmentingPoiChange({
                rawScanResults,
                poi,
                handleImgUrl,
                setSelectedPoi,
                setFullScreenImgUrl
              })
            }
            imageSrc={fullScreenImgUrl}
            onClose={setOpenDistanceMapper}
            open={openDistanceMapper}
            poiDistanceSegmentState={poiDistanceSegmentState}
            poiKeys={poiKeys}
            selectedCamera={selectedCamera}
            selectedPoi={selectedPoi}
            selectedScan={selectedScan}
          />

          <CreateAlarmDialog
            setHumanActivityFocus={setHumanActivityFocus}
            open={creatingAlarm || isAlarmEditing}
            title={isAlarmEditing ? "Requantify Alarm" : "Alarm Creation"}
            alarmStart={isAlarmEditing ? selectedAlarm?.start : alarmStart}
            handleAlarmStart={handleAlarmStart}
            alarmEnd={isAlarmEditing ? selectedAlarm?.end : alarmEnd}
            handleAlarmEnd={handleAlarmEnd}
            onClose={handleCloseAlarmDialog}
            handleNewAlarm={handleNewAlarm}
            scans={scanResults}
            saveButtonRef={saveButtonRef}
            handleFullscreenImage={handleFullscreenImage}
            imgType={imgType}
            quantificationProps={quantificationProps}
          />
        </div>
      </div>
    </div>
  );
};

ImageList.propTypes = {
  addEditAlarm: PropTypes.func.isRequired,
  scanIndex: PropTypes.number.isRequired,
  setScanIndex: PropTypes.func.isRequired,
  scanResults: PropTypes.array.isRequired,
  setScanResults: PropTypes.func.isRequired,
  poiKeys: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  eventConf: PropTypes.number.isRequired,
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  handleDateChange: PropTypes.func.isRequired,
  resetAlarmCreationSession: PropTypes.func.isRequired,
  selectedPoi: PropTypes.string.isRequired,
  setSelectedPoi: PropTypes.func.isRequired,
  deviceId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  rawScanResults: PropTypes.array
};

export default ImageList;
