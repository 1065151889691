import { isNil } from "lodash";
import { shallowEqual, useSelector } from "react-redux";

import { selectAppSettings } from "~/common/selectors/AppSettingsSelector";
import { validForQuantification } from "~/constants/reviewer-lookups";
import { UnitNames } from "~/constants/units";
import { convertGasUnit } from "~/utils/quantification-utils";

export const useQuantification = alarm => {
  const hasValidDistance = !isNil(alarm?.leakSource?.rangeMeters);

  const { isImperialUnits } = useSelector(selectAppSettings, shallowEqual);

  const algoResult = { ...alarm?.quantResult?.resultMB, id: "MB" };
  const unit = alarm?.quantResult?.units ?? alarm?.quantification?.units;

  const averageRate = convertGasUnit(
    algoResult?.avg || alarm?.quantification?.avg, // in standard cubic meters (Sm3/d)
    unit,
    isImperialUnits
  );

  const leakAvg = getLeakAvg(averageRate, isImperialUnits);
  const quantificationResult = hasValidDistance
    ? alarm?.avgTshirtSize
    : leakAvg;
  const hasQuantification = alarm?.avgTshirtSize || averageRate !== "N/A";
  const isSourceValidForQuantification =
    validForQuantification?.[alarm?.source?.toLowerCase()] || false;

  return {
    avgTshirtSize: alarm?.avgTshirtSize || "N/A",
    distance: alarm?.leakSource?.rangeMeters || "N/A",
    hasQuantification,
    hasValidDistance,
    isSourceValidForQuantification,
    leakAvg,
    source: alarm?.source || "N/A",
    quantificationResult: quantificationResult || "N/A"
  };
};

const getLeakAvg = (averageRate, isImperialUnits) => {
  if (averageRate === "N/A") return "N/A";

  return `${averageRate} ${
    isImperialUnits
      ? UnitNames.STANDARD_CUBIC_FEET_PER_HOUR
      : UnitNames.CUBIC_METERS_PER_DAY
  }`;
};
