import { Chip } from "@mui/material";
import PropTypes from "prop-types";

const Tags = ({ tags, updateTags }) => {
  const handleDelete = tag => {
    const text = tag;
    updateTags(tags.filter(tag => tag !== text));
  };

  return (
    <div>
      {tags.map(tag => (
        <Chip
          key={tag}
          variant="outlined"
          color="primary"
          size="small"
          style={{
            margin: "5px"
          }}
          label={tag}
          onDelete={() => handleDelete(tag)}
        />
      ))}
    </div>
  );
};

Tags.propTypes = {
  tags: PropTypes.array.isRequired,
  updateTags: PropTypes.func.isRequired
};

export default Tags;
