import { useFeatureIsOn } from "@growthbook/growthbook-react";
import {
  Cancel as CancelIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon
} from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { flags } from "~/constants/feature-flags";
import data from "~/pages/settingsPage/TagManagement/data.json";
import {
  organization,
  tagTypes
} from "~/pages/settingsPage/TagManagement/filterOptions";

import AddTagDialog from "./AddTagDialog";
import FilterTagsToggle from "./FilterTagsToggle";
import { useStyles } from "./styles";

const AlarmTagsSelect = ({
  selectedAlarmTags,
  handleChange,
  handleDelete,
  menuProps
}) => {
  const isTagsFilteringOn = useFeatureIsOn(flags.TAGS_FILTERING_ALARM_CREATION);
  const { classes } = useStyles();

  const tagsData = data.tags.filter(tag => {
    return tag.type === tagTypes.alarm;
  });

  const [tagsFilter, setTagsFilter] = useState(organization.all);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    let filteredTags = [];
    switch (tagsFilter) {
      case organization.default:
        filteredTags = tagsData
          .filter(tag => tag.id < 15 && tag.orgId === 0)
          .map(obj => obj.text);
        break;
      case organization.custom:
        filteredTags = tagsData
          .filter(tag => tag.orgId !== 0)
          .map(obj => obj.text);
        break;
      default:
        filteredTags = tagsData.map(obj => obj.text);
    }
    setTagsOptions(filteredTags);
  }, [tagsFilter]);

  const addCustomTag = () => {
    setOpenDialog(true);
  };

  return (
    <>
      <FormControl variant="standard" className={classes.formControl}>
        <InputLabel
          className={classes.tagsInputLabel}
          id="demo-mutiple-chip-checkbox-label"
        >
          Tags
        </InputLabel>
        <Select
          labelId="demo-mutiple-chip-checkbox-label"
          data-testid="alarm-tags-select"
          multiple
          variant="outlined"
          value={selectedAlarmTags}
          onChange={handleChange}
          IconComponent={KeyboardArrowDownIcon}
          renderValue={selected => (
            <div className={classes.chips}>
              {selected.map(value => (
                <Chip
                  key={value}
                  label={value}
                  clickable
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={event => event.stopPropagation()}
                    />
                  }
                  className={classes.chip}
                  variant="outlined"
                  color="primary"
                  onDelete={e => handleDelete(e, value)}
                />
              ))}
            </div>
          )}
          MenuProps={{
            ...menuProps,
            getContentAnchorEl: null,
            style: { maxHeight: "400px" }
          }}
        >
          {isTagsFilteringOn && (
            <>
              <div className={classes.toggle} value="unselectable">
                <FilterTagsToggle
                  tagsFilter={tagsFilter}
                  setTagsFilter={setTagsFilter}
                />
              </div>
              <div value="unselectable" style={{ textAlign: "center" }}>
                <Button
                  onClick={addCustomTag}
                  style={{ border: "1px solid gray" }}
                >
                  Add custom tag
                </Button>
              </div>
            </>
          )}
          {tagsOptions.map(name => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={selectedAlarmTags?.includes(name)} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* TODO (lily): when Tags Management branch is merged to dev, replace component with that dialog */}
      <AddTagDialog setOpenDialog={setOpenDialog} open={openDialog} />
    </>
  );
};

AlarmTagsSelect.defaultProps = {
  selectedAlarmTags: []
};

AlarmTagsSelect.propTypes = {
  selectedAlarmTags: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  menuProps: PropTypes.object
};

export default AlarmTagsSelect;
