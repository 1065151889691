import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useRef } from "react";

import DetectionBox from "~/common/components/dialogs/detectionbox/DetectionBox";
import { handleImgUrl } from "~/common/utils/functionUtils";

import { useStyles } from "./Styles";

const ExpandedImage = ({
  scan,
  event,
  handleFullscreenImage,
  setScanResults,
  setCurrentImageConfidence
}) => {
  const columnDensityImage = useRef(null);
  const showDetectionBoxes =
    sessionStorage.getItem("showDetectionBoxes") === "false" ? false : true;
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={3}>
          {/** TODO: if there isn't an RGB??? */}
          <div>
            <Typography>RGB</Typography>
            <img
              key={scan.rgb}
              src={scan.rgb}
              width="640"
              height="480"
              alt="rgb_image"
              onClick={() => handleFullscreenImage("rgb")}
              style={{
                width: "100%",
                height: "auto",
                backgroundColor: "#222",
                outline: event !== null ? "1px solid cyan" : "1px solid grey"
              }}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div>
            <Typography>Column density</Typography>
            <div
              style={{
                position: "relative"
              }}
            >
              <img
                ref={columnDensityImage}
                key={scan.jpg}
                src={scan.jpg}
                width="313"
                height="410"
                alt="gas_image"
                onClick={() => handleFullscreenImage("colDen")}
                style={{
                  width: "100%",
                  height: "auto",
                  backgroundColor: "#222",
                  outline: event !== null ? "1px solid cyan" : "1px solid grey"
                }}
              />
              {scan?.detections?.length > 0 &&
                showDetectionBoxes &&
                scan.detections.map((detection, index) => {
                  return (
                    <DetectionBox
                      key={detection.uuid}
                      img={columnDensityImage}
                      detection={detection}
                      scan={scan}
                      editable={true}
                      index={index}
                      setScanResults={setScanResults}
                      setCurrentImageConfidence={setCurrentImageConfidence}
                    />
                  );
                })}
            </div>
          </div>
        </Grid>
        <Grid item xs={3}>
          {console.log("scan.jpg", scan.jpg)}
          <div>
            <Typography>Colorized OD</Typography>
            <img
              key={handleImgUrl(scan.jpg, "colOD")}
              src={handleImgUrl(scan.jpg, "colOD")}
              width="313"
              height="410"
              alt="colorOD"
              onClick={() => handleFullscreenImage("colOD")}
              style={{
                width: "100%",
                height: "auto",
                backgroundColor: "#222",
                outline: event !== null ? "1px solid cyan" : "1px solid grey"
              }}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div>
            <Typography>Unmasked</Typography>
            <img
              key={handleImgUrl(scan.jpg, "unmasked")}
              src={handleImgUrl(scan.jpg, "unmasked")}
              width="313"
              height="410"
              alt="unmasked"
              onClick={() => handleFullscreenImage("unmasked")}
              style={{
                width: "100%",
                height: "auto",
                backgroundColor: "#222",
                outline: event !== null ? "1px solid cyan" : "1px solid grey"
              }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

ExpandedImage.propTypes = {
  scan: PropTypes.object.isRequired,
  event: PropTypes.number,
  handleFullscreenImage: PropTypes.func.isRequired,
  setScanResults: PropTypes.func.isRequired,
  setCurrentImageConfidence: PropTypes.func.isRequired
};

export default ExpandedImage;
