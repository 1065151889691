import { initialState } from "./initialState";

export default function camerasReducer(
  state = initialState.applicationSettings,
  action
) {
  switch (action.type) {
    case "UPDATE_SELECTED_RESULTS_CONTAINER":
      window.localStorage.setItem(
        "applicationSettings.selectedResultsContainer",
        action.selectedContainer
      );
      return { ...state, selectedResultsContainer: action.selectedContainer };
    case "UPDATE_SHOW_NON_DETECTIONS":
      window.localStorage.setItem(
        "applicationSettings.showNonDetection",
        action.showNonDetection.toString()
      );
      return { ...state, showNonDetection: action.showNonDetection };
    case "UPDATE_DISPLAY_UTC":
      window.localStorage.setItem(
        "applicationSettings.displayUtc",
        action.displayUtc.toString()
      );
      return { ...state, displayUtc: action.displayUtc };
    case "SET_IS_IMPERIAL_UNITS":
      window.localStorage.setItem(
        "applicationSettings.isImperialUnits",
        action.isImperialUnits.toString()
      );
      return { ...state, isImperialUnits: action.isImperialUnits };
    default:
      return { ...state };
  }
}
