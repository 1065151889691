import { Badge, Box, TextField } from "@mui/material";
import { DatePicker, PickersDay } from "@mui/x-date-pickers-pro";
import PropTypes from "prop-types";

import { styled } from "@kuva/ui-components";

import { getCurrentYearMonth } from "~/common/utils/dataUtils";
import { toUTC } from "~/common/utils/dateTimeUtils";
import { useGetAlarms } from "~/hooks";

const { year, month } = getCurrentYearMonth();
const endOfUTCDay = date => {
  date.setUTCHours(23, 59, 59, 999);
  return date;
};

const pickersDaySx = {
  color: "#FFFFFF",
  backgroundColor: "unset",
  "&.Mui-disabled": {
    color: "#808080"
  }
};

const datePickerSx = {
  "&.MuiCalendarOrClockPicker-root": {
    backgroundColor: "red"
  }
};

const DayContainer = styled("div")({
  width: 40,
  height: 42,
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const UTCDatePicker = ({ date, onChange }) => {
  const { alarmsGroupedByMonth, handleGetAlarmByMonth } = useGetAlarms();

  const formattedDate = toUTC(date);
  const groupArray = alarmsGroupedByMonth();
  const handleMonthChangeEv = async (year, month) =>
    await handleGetAlarmByMonth(year, month);

  const handleMonthChange = date => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    handleMonthChangeEv(year, month);
  };

  const handleDate = async day => {
    onChange(day);
    const noData = groupArray.length === 0;
    const lastDate = noData ? [] : Array.from(groupArray.values()).pop()[0];
    const lastAlarmDate = new Date(lastDate);
    let [year, month] = day.toISOString().split("T")[0].split("-");
    const needToFetchMoreAlarms = lastAlarmDate.getTime() > day.getTime();
    if (needToFetchMoreAlarms) await handleGetAlarmByMonth(year, month);
  };

  const renderCustomDay = ({
    day,
    groupArray,
    pickersDayProps,
    pickersDaySx
  }) => {
    const jsonDay = day.toJSON().substring(0, 10);
    const map = new Map(groupArray);
    const numberOfAlarms = map.get(jsonDay)?.length || 0;

    return (
      <DayContainer>
        <Badge
          badgeContent={numberOfAlarms}
          color="secondary"
          overlap="circular"
        >
          <PickersDay {...pickersDayProps} day={day} sx={pickersDaySx} />
        </Badge>
      </DayContainer>
    );
  };

  return (
    <DatePicker
      onOpen={() => handleMonthChangeEv(year, month + 1)}
      inputFormat="MMMM d, yyyy"
      value={formattedDate}
      onChange={handleDate}
      renderInput={({ inputRef, inputProps, InputProps }) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {InputProps?.endAdornment}
          <TextField
            inputRef={inputRef}
            {...inputProps}
            variant="standard"
            InputProps={{
              disableUnderline: true
            }}
            sx={{
              "& .MuiInputBase-input": {
                fontSize: "20px",
                marginLeft: "20px",
                color: "#FFFFFF"
              }
            }}
          />
        </Box>
      )}
      onMonthChange={handleMonthChange}
      renderDay={(day, _selectedDates, pickersDayProps) =>
        renderCustomDay({ day, groupArray, pickersDayProps, pickersDaySx })
      }
      showDaysOutsideCurrentMonth
      maxDate={endOfUTCDay(new Date())}
      sx={datePickerSx}
    />
  );
};

UTCDatePicker.propTypes = {
  date: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default UTCDatePicker;
