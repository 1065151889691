import { combineReducers } from "@reduxjs/toolkit";

import { PoiDistanceSegmentsReducer } from "~/common/store/slices/poi-distance-segments";
import { CameraPresetListsPageReducer } from "~/common/store/slices/camera-preset-lists";
import { CamerasFovReducer } from "~/common/store/slices/camerasFov";
import { OrganizationsReducer } from "~/common/store/slices/organizations/organizations-slice";
import { AlgorithmRecord } from "~/common/store/slices/algorithm-records";

import alarms from "./alarmsReducer";
import cameras from "./camerasReducer";
import sessions from "./sessionsReducer";
import settings from "./settingsReducer";
import shifts from "./shiftsReducer";

const rootReducer = combineReducers({
  cameraFovState: CamerasFovReducer,
  cameraState: cameras,
  alarmState: alarms,
  shiftState: shifts,
  sessionState: sessions,
  cameraPresetListsState: CameraPresetListsPageReducer,
  applicationSettings: settings,
  organizationState: OrganizationsReducer,
  poiDistanceSegmentState: PoiDistanceSegmentsReducer,
  algorithmRecordState: AlgorithmRecord
});

export default rootReducer;
