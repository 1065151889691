import { useState, useRef } from "react";
import PropTypes from "prop-types";

import LeakSourceBox from "~/containers/LeakSourceLocationTool/components/LeakSourceBox";
import LeakSourcePoint from "~/containers/LeakSourceLocationTool/components/LeakSroucePoint";
import DetectionBox from "~/common/components/dialogs/detectionbox/DetectionBox";
import { handleImgUrl } from "~/common/utils/functionUtils";

import { useStyles } from "./styles";

export const MultiImageView = ({ hoveredScan, leakRois, leakSource }) => {
  const showDetectionBoxes =
    sessionStorage.getItem("showDetectionBoxes") === "false" ? false : true;
  const highlightedImg = useRef(null);
  const swirImg = useRef(null);

  const [swirImgLoaded, setSwirImgLoaded] = useState(false);
  const [imgType, setImgType] = useState("swir");

  const { classes } = useStyles({ imgType });

  const handleImg = () => {
    if (imgType === "unmasked" || imgType === "colorOD") {
      return handleImgUrl(hoveredScan.jpg, imgType);
    } else if (imgType === "rgb") {
      return hoveredScan.rgb;
    } else {
      return hoveredScan.jpg;
    }
  };

  if (!hoveredScan) {
    return <div>No Selected Image</div>;
  }

  return (
    <div>
      <div className={classes.displayedImage}>
        <img
          ref={highlightedImg}
          onLoad={() => setSwirImgLoaded(true)}
          key={hoveredScan.jpg + "IMG"}
          src={handleImg()}
          alt="mainImage"
          width="313"
          height="410"
          className={classes.image}
        />
        {imgType === "swir" &&
          hoveredScan?.detections?.length > 0 &&
          showDetectionBoxes &&
          swirImgLoaded &&
          hoveredScan?.detections?.map((detection, index) => {
            return (
              <DetectionBox
                key={detection.uuid}
                img={highlightedImg}
                detection={detection}
                index={index}
                scan={hoveredScan}
              />
            );
          })}
        {imgType !== "rgb" &&
          leakRois?.length > 0 &&
          hoveredScan &&
          swirImgLoaded && (
            <LeakSourceBox
              key={hoveredScan?.id}
              img={highlightedImg}
              leakSource={leakRois.at(0)}
              scan={hoveredScan}
            />
          )}
        {imgType !== "rgb" && leakSource?.coords?.length > 0 && (
          <LeakSourcePoint
            key={`leak-source-point-selected-${hoveredScan?.id}`}
            img={highlightedImg}
            leakOrigin={leakSource?.coords}
          />
        )}
      </div>

      <div className={classes.imageTypeSelector}>
        <img
          key={hoveredScan.rgb}
          src={hoveredScan.rgb}
          alt="rgb"
          onClick={() => setImgType("rgb")}
          width="640"
          height="480"
          className={classes.rgbPreview}
        />
        <img
          ref={swirImg}
          key={hoveredScan.jpg}
          src={hoveredScan.jpg}
          alt="swir"
          onClick={() => setImgType("swir")}
          width="313"
          height="410"
          className={classes.swirPreview}
        />
        <img
          key={handleImgUrl(hoveredScan.jpg, "unmasked")}
          src={handleImgUrl(hoveredScan.jpg, "unmasked")}
          alt="unmasked"
          onClick={() => setImgType("unmasked")}
          width="313"
          height="410"
          className={classes.unmaskedPreview}
        />
        <img
          key={handleImgUrl(hoveredScan.jpg, "colorOD")}
          src={handleImgUrl(hoveredScan.jpg, "colorOD")}
          alt="colorOD"
          onClick={() => setImgType("colorOD")}
          width="313"
          height="410"
          className={classes.colorODPreview}
        />
      </div>
    </div>
  );
};

MultiImageView.propTypes = {
  hoveredScan: PropTypes.object.isRequired,
  leakRois: PropTypes.object.isRequired,
  leakSource: PropTypes.object.isRequired
};
