import { createSlice } from "@reduxjs/toolkit";

import { Camera } from "~/common/models/Camera";

import { deleteCameraList } from "../thunks/delete-camera-preset-list";
import { fetchCameraListsByHub } from "../thunks/get-camera-preset-lists-by-hub";
import { fetchIotHubCameras } from "../thunks/get-iot-hub-cameras";
import { upsertCameraList } from "../thunks/upsert-camera-preset-list";

const initialState = {
  error: null,
  cameras: [],
  cameraLists: [],
  loading: false,
  loadingCameras: false,
  organizations: [],
  openForm: false,
  selectedCameraList: null,
  selectedIotHub: null,
  selectedOrg: null
};

const slice = createSlice({
  name: "camera-preset-lists",
  initialState,
  reducers: {
    setOpenForm: (state, action) => {
      state.openForm = action.payload;
    },
    setSelectedCameraList: (state, action) => {
      state.selectedCameraList = action?.payload?.cameraList;
    },
    setOrganizations: (state, action) => {
      state.organizations = action.payload;
    },
    setSelectedOrg: (state, action) => {
      state.selectedOrg = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCameraListsByHub.pending, state => {
        state.loading = true;
      })
      .addCase(fetchCameraListsByHub.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedOrg = null;
        state.cameraLists = action?.payload?.data;
        state.selectedIotHub = action?.meta?.arg;
      })
      .addCase(fetchCameraListsByHub.rejected, (state, action) => {
        const error = {
          error: true,
          errorOn: action.payload
        };
        state.error = error;
        state.loading = false;
      })

      .addCase(upsertCameraList.pending, state => {
        state.loading = true;
      })
      .addCase(upsertCameraList.fulfilled, (state, action) => {
        const existingItemIndex = state.cameraLists.findIndex(
          item => item.id === action?.payload?.data?.id
        );
        if (existingItemIndex !== -1) {
          state.cameraLists[existingItemIndex] = action?.payload?.data;
        } else {
          state.cameraLists = [action?.payload?.data, ...state.cameraLists];
        }
        state.openForm = false;
        state.loading = false;
      })
      .addCase(upsertCameraList.rejected, state => {
        state.loading = false;
      })

      .addCase(fetchIotHubCameras.pending, state => {
        state.loadingCameras = true;
      })
      .addCase(fetchIotHubCameras.fulfilled, (state, action) => {
        const cameras = action?.payload?.response?.data.map(
          cam => new Camera(cam)
        );
        state.cameras = cameras;
        state.loadingCameras = false;
      })
      .addCase(fetchIotHubCameras.rejected, (state, action) => {
        const error = {
          error: true,
          errorOn: action?.payload
        };
        state.error = error;
        state.loadingCameras = false;
      })

      .addCase(deleteCameraList.pending, state => {
        state.loading = true;
      })
      .addCase(deleteCameraList.fulfilled, (state, action) => {
        state.cameraLists = state.cameraLists.filter(item => {
          return item.id !== action?.payload?.cameraListId;
        });
        state.selectedCameraList = null;
        state.loading = false;
        state.openForm = false;
      })
      .addCase(deleteCameraList.rejected, (state, action) => {
        const error = {
          error: true,
          errorOn: action?.payload
        };
        state.error = error;
        state.loading = false;
      });
  }
});

export const CameraPresetListsActions = {
  ...slice.actions,
  deleteCameraList,
  fetchCameraListsByHub,
  fetchIotHubCameras,
  upsertCameraList
};

export const CameraPresetListsPageReducer = slice.reducer;
