import { Box, Typography } from "@mui/material";
import { GlobalStyle } from "GlobalStyle";
import PropTypes from "prop-types";

import {
  getWindDirection,
  getWindSpeed,
  getFloatingNumber
} from "@kuva/ui-helpers";

const Telemetry = ({ telemetry }) => {
  const { classes: globalStyle } = GlobalStyle();
  return (
    <Box className={globalStyle.dataBox}>
      <Typography className={globalStyle.dataBoxTitle}>TELEMETRY</Typography>
      {telemetry ? (
        <>
          {telemetry.created_time != null && (
            <Typography className={globalStyle.font14}>
              Created Time: {telemetry.created_time}
            </Typography>
          )}
          {telemetry.board_temp != null && (
            <Typography className={globalStyle.font14}>
              Board Temp:
              {getFloatingNumber(telemetry.board_temp)}
              °C
            </Typography>
          )}
          {telemetry.sensor_temp != null && (
            <Typography className={globalStyle.font14}>
              Sensor Temp: {getFloatingNumber(telemetry.sensor_temp)}
              °C
            </Typography>
          )}
          {telemetry.pan_pos != null && (
            <Typography className={globalStyle.font14}>
              Pan Pos: {telemetry.pan_pos}°
            </Typography>
          )}
          {telemetry.wind_time != null && (
            <Typography className={globalStyle.font14}>
              Wind Time: {telemetry.wind_time}
            </Typography>
          )}
          {telemetry.wind_direction != null && (
            <Typography className={globalStyle.font14}>
              Wind Direction: {getWindDirection(telemetry?.wind_direction)}
            </Typography>
          )}
          {telemetry.wind_speed != null && (
            <Typography className={globalStyle.font14}>
              Wind Speed: {getWindSpeed(telemetry.wind_speed, "m/s")}
            </Typography>
          )}
        </>
      ) : (
        <Typography className={globalStyle.font14}> Not Available </Typography>
      )}
    </Box>
  );
};

Telemetry.propTypes = {
  telemetry: PropTypes.object,
  getFloatingNumber: PropTypes.func.isRequired
};

export default Telemetry;
