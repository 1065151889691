import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Loading from "~/common/components/loading/Loading";

export const LoadingIndicator = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "auto"
      }}
    >
      <Typography variant="h6">Loading...</Typography>
      <Loading />
    </Box>
  );
};
