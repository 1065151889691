export const leakSourceTags = [
  "Tanks",
  "Compressors",
  "Separators",
  "Flares",
  "Tanker Truck",
  "Off site",
  "Out-of-scene",
  "Other"
];

export const leakSourceCodes = {
  tanks: "T",
  compressors: "C",
  separators: "S",
  flares: "F",
  "tanker truck": "TT",
  "off site": "OS",
  "out-of-scene": "OOS",
  other: "O"
};

export const validForQuantification = {
  tanks: true,
  compressors: true,
  separators: true,
  flares: true,
  "tanker truck": true,
  "off site": true,
  "out-of-scene": false,
  other: true
};
