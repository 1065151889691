import { useCallback, useRef, useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  getAlarmByMonth,
  getAlarmByPagination,
  setLoading
} from "~/common/actions/alarmsActions";
import {
  getAlarmState,
  selectSelectedPois
} from "~/common/selectors/AlarmSelector";
import { groupByDate } from "~/common/utils/alarmUtils";

const alarmPerPageOptions = [25, 50, 100];

const useGetAlarms = _deviceId => {
  let { deviceId } = useParams();
  deviceId = _deviceId ? _deviceId : deviceId;
  const alarmContainerRef = useRef(null);
  const pageSize = alarmPerPageOptions.at(0);

  const [alarmsPerPage, setAlarmsPerPage] = useState(pageSize);

  const { alarms, loading, pagination, alarmsByMonth } = useSelector(
    getAlarmState,
    shallowEqual
  );

  const selectedPois = useSelector(selectSelectedPois, shallowEqual);
  const poiList = selectedPois.map(a => a.poi);
  const alarmsFiltered =
    alarms.filter(alarm => poiList.includes(alarm.poiOrientation)) ?? alarms;

  const isPaginationDisabled = alarms.length >= pagination?.total;
  const dispatch = useDispatch();
  const groupedAlarmsArrayOfMap = useCallback(
    () =>
      Array.from(
        groupByDate(alarmsFiltered.length > 0 ? alarmsFiltered : alarms)
      )
        .sort()
        .reverse(),
    [alarms.length, alarms[0]?.id, selectedPois]
  );

  const alarmsGroupedByMonth = useCallback(
    () => Array.from(groupByDate(alarmsByMonth)).sort().reverse(),
    [alarmsByMonth.length, deviceId, selectedPois]
  );

  const loadInitialAlarms = () => {
    deviceId && dispatch(getAlarmByPagination(deviceId, 1, pageSize, true));
  };

  const loadMoreAlarms = () => {
    /* Check if the device has changed to ensure that the alarms the user is seeing
       belong to the current device and don't mix with alarms from other devices.
    */
    const resetRequired = alarms?.at(0)?.deviceId !== deviceId;
    const nextPage = parseInt(pagination?.page) + 1;
    dispatch(
      getAlarmByPagination(deviceId, nextPage, alarmsPerPage, resetRequired)
    ).then(() => {
      if (alarmContainerRef.current) {
        alarmContainerRef.current.scrollTop =
          alarmContainerRef.current.scrollHeight;
      }
    });
  };

  const handleAlarmsPerPageChange = event => {
    /* Reset the alarms list when the page size changes */
    const newSelectedPageSize = event.target.value;
    dispatch(getAlarmByPagination(deviceId, 1, newSelectedPageSize, true));
    setAlarmsPerPage(newSelectedPageSize);
  };

  const handleGetAlarmByMonth = (year, month) =>
    dispatch(getAlarmByMonth(deviceId, year, month));

  useEffect(() => {
    dispatch({
      type: "RESET_ALARMS_BY_MONTH"
    });
  }, [deviceId, dispatch]);

  return {
    loading,
    alarms,
    setLoading,
    groupedAlarmsArrayOfMap,
    isPaginationDisabled,
    loadInitialAlarms,
    loadMoreAlarms,
    alarmsPerPage,
    handleAlarmsPerPageChange,
    alarmPerPageOptions,
    alarmContainerRef,
    handleGetAlarmByMonth,
    alarmsGroupedByMonth
  };
};

export default useGetAlarms;
