export function selectAppSettings(state) {
  return state.applicationSettings;
}

export function getSelectedContainerSetting(state) {
  return state.applicationSettings.selectedResultsContainer;
}

export function getShowNonDetectionsSetting(state) {
  return state.applicationSettings.showNonDetection;
}

export function getDisplayUtc(state) {
  return state.applicationSettings.displayUtc;
}
