import { Box, Typography } from "@mui/material";
import { GlobalStyle } from "GlobalStyle";
import PropTypes from "prop-types";

const MetadataInfo = ({ selectedScan, currentImageConfidence }) => {
  const { classes: globalStyle } = GlobalStyle();
  return (
    <Box className={globalStyle.dataBox}>
      <Typography className={globalStyle.dataBoxTitle}>METADATA</Typography>
      {/* <Typography> Event: {event ?? "Not Found"} </Typography> */}
      <Typography className={globalStyle.font14}>
        Date: {selectedScan.createdOn.substring(0, 10).replace(/-/g, "/")}
      </Typography>
      <Typography className={globalStyle.font14}>
        Time: {selectedScan.createdOn.substring(11, 19)}
      </Typography>
      <Typography className={globalStyle.font14}>
        Confidence: {currentImageConfidence}
      </Typography>
      <Typography className={globalStyle.font14}>
        Noise Floor: {Math.round(selectedScan.noiseFloorMetric)}
      </Typography>
      <Typography className={globalStyle.font14}>
        Poi Orientation: {selectedScan.poiOrientation ?? "None"}
      </Typography>
    </Box>
  );
};

MetadataInfo.propTypes = {
  selectedScan: PropTypes.object.isRequired,
  currentImageConfidence: PropTypes.string.isRequired
};

export default MetadataInfo;
