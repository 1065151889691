import { ListItem, ListItemText, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import { leakSourceCodes } from "~/constants/reviewer-lookups";
import { useQuantification } from "~/hooks";

import { useAlarmListItemStyles } from "./styles";

export const AlarmListItem = ({
  alarm,
  selectedAlarm,
  selectedOrg,
  selectedCamera
}) => {
  const quantificationData = useQuantification(alarm);
  const {
    hasValidDistance,
    hasQuantification,
    isSourceValidForQuantification
  } = quantificationData || {};

  const { classes } = useAlarmListItemStyles({
    alarm,
    hasQuantification,
    hasValidDistance,
    selectedAlarm,
    isSourceValidForQuantification
  });

  return (
    <ListItem
      component={NavLink}
      key={alarm.id}
      data-testid="alarm-list-item"
      to={`/alarms/${selectedOrg?.id}/${selectedCamera?.deviceId}/${alarm.id}`}
      className={classes.listItem}
    >
      <ListItemText
        primaryTypographyProps={{
          className: classes.primaryTypography
        }}
      >
        {alarm.start.substring(11, 16)}–{alarm.end.substring(11, 16)}
        <Tooltip title={alarm?.source}>
          <span>
            - {leakSourceCodes[alarm?.source?.toLowerCase()] ?? "N/A"}
          </span>
        </Tooltip>
        <span className={classes.poiLabel}>
          [{Math.round(alarm?.poiOrientation) ?? "All"}]
        </span>
      </ListItemText>
    </ListItem>
  );
};

AlarmListItem.propTypes = {
  alarm: PropTypes.object.isRequired,
  selectedAlarm: PropTypes.object,
  selectedOrg: PropTypes.object,
  selectedCamera: PropTypes.object
};
