import { useFeatureIsOn } from "@growthbook/growthbook-react";
import {
  Autocomplete,
  ClickAwayListener,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import { flags } from "~/constants/feature-flags";
import data from "~/pages/settingsPage/TagManagement/data.json";
import {
  organization,
  tagTypes
} from "~/pages/settingsPage/TagManagement/filterOptions";

import { useStyles } from "./styles.js";

const TagTextField = ({ tags, updateTags, setTagsFocus, displayType }) => {
  let textInput = useRef(null);
  const [tagsFilter, setTagsFilter] = useState(organization.all);
  const [displayedTags, setDisplayedTags] = useState([]);
  const [showTags, setShowTags] = useState(false);

  const isTagsFilteringOn = useFeatureIsOn(flags.TAGS_FILTERING_NAV_BAR);
  const { classes } = useStyles({ displayType });

  const tagsData = data.tags.filter(tag => {
    return tag.type === tagTypes.image;
  });

  const handleClickAway = () => {
    setShowTags(false);
  };

  const handleChange = (event, value) => {
    if (value && !tags.includes(value)) {
      updateTags([...tags, value]);
      setShowTags(false);
    }
  };

  const handleNew = ({ target: { value }, key }) => {
    const text = value;
    if (key === "Enter" && value.trim().length > 0) {
      textInput.current.value = "";
      if (tags.includes(text)) {
        return;
      }
      updateTags([...tags, text]);
    }
  };

  const handleOpen = () => {
    setShowTags(true);
  };

  const handleFocus = () => {
    setTagsFocus(true);
  };

  const handleBlur = () => {
    setShowTags(false);
    setTagsFocus(false);
  };

  const handleClick = (event, newFilter) => {
    setTagsFilter(newFilter);
  };

  const handleMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  useEffect(() => {
    let filteredTags = [];
    switch (tagsFilter) {
      case organization.default:
        filteredTags = tagsData
          .filter(tag => tag.id < 15 && tag.orgId === 0)
          .map(obj => obj.text);
        break;
      case organization.custom:
        filteredTags = tagsData
          .filter(tag => tag.orgId !== 0)
          .map(obj => obj.text);
        break;
      default:
        filteredTags = tagsData.map(obj => obj.text);
    }
    setDisplayedTags(filteredTags);
  }, [tagsFilter]);

  return (
    <>
      <Typography className={classes.textfieldLabel}>Tags</Typography>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <Autocomplete
            options={displayedTags}
            onChange={handleChange}
            disableClearable
            onOpen={handleOpen}
            noOptionsText={"No tags found"}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                inputRef={textInput}
                onKeyDown={handleNew}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={classes.textfield}
              />
            )}
          />

          {isTagsFilteringOn && showTags && (
            <div className={classes.filterTags}>
              <Typography variant="subtitle2">Filter by:</Typography>
              <ToggleButtonGroup
                value={tagsFilter}
                exclusive
                onChange={handleClick}
                aria-label="filter by"
              >
                <ToggleButton
                  value={organization.default}
                  aria-label="default"
                  onMouseDown={handleMouseDown}
                  className={classes.toggleBtn}
                >
                  <div>Default</div>
                </ToggleButton>
                <ToggleButton
                  value={organization.custom}
                  aria-label="custom"
                  onMouseDown={handleMouseDown}
                  className={classes.toggleBtn}
                >
                  <div>Custom</div>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </>
  );
};

TagTextField.propTypes = {
  tags: PropTypes.array,
  updateTags: PropTypes.func,
  setTagsFocus: PropTypes.func,
  displayType: PropTypes.string
};

export default TagTextField;
