import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ExitToApp from "@mui/icons-material/ExitToApp";
import {
  AppBar,
  Button,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Select,
  Toolbar
} from "@mui/material";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";

import { makeStyles } from "@kuva/ui-components";

import { useOrganization } from "~/hooks";

import OrganizationSelect from "../common/components/dropdowns/OrganizationSelect";
import {
  getOrgCameras,
  getSelectedCamera
} from "../common/selectors/CameraSelector";
import Theme from "../common/Theme";
import {
  findOrganizationAndDescendant,
  getOrgIds
} from "../common/utils/organization";

const useStyles = makeStyles()({
  bar: {
    zIndex: 10000,
    height: "5em",
    paddingBottom: "10px"
  },

  select: {
    fontSize: "12px"
  },
  fontSize14: {
    fontSize: "14px"
  }
});

const Header = ({ reviewerMode }) => {
  const { classes } = useStyles();
  const { organizations, selectedOrg, setSelectedOrg } = useOrganization();
  const history = useHistory();
  //Not usning the useRouteMatch("/:orgId/:deviceId/"); it limits routing request and to avoid conditional routing
  //Added specific route eg. /events
  const location = useLocation();
  const params = location.pathname.split("/");
  const pageBaseUrl = params[1];
  const orgId = params[2];
  const deviceId = params[3];
  const sessionReviewerRoutes = [
    "sessions-landing",
    "sessions",
    "session-events"
  ];

  /* Find organization and descendant and return as an Object.*/
  const organizationWithDescendant = useMemo(
    () => findOrganizationAndDescendant(organizations, selectedOrg?.id),
    [selectedOrg]
  );
  /* Return org ids in a flatten array from organizationWithDescendant object
   * e.g(['kuva-usa', 'kuva-massachusetts', 'kuva-texas', 'kuva-lubbok'])
   * or return ['all'] if "All" is selected */
  const orgIds =
    !isEmpty(selectedOrg) &&
    !isEmpty(organizationWithDescendant) &&
    selectedOrg?.id !== "all"
      ? getOrgIds(organizationWithDescendant)
      : ["all"];

  const [orgsAnchorEl, setOrgsAnchorEl] = useState(null);

  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const cameras = useSelector(getOrgCameras(orgIds), shallowEqual);
  const selectedCamera = useSelector(getSelectedCamera, shallowEqual);

  useEffect(() => {
    // Update selected device based on requested URL
    if (
      typeof deviceId !== "undefined" &&
      deviceId !== "undefined" &&
      deviceId !== null
    ) {
      dispatch({ type: "SET_SELECTED_CAMERA", deviceId });
    }
  }, [deviceId, dispatch]);

  const handleOrgsClick = event => setOrgsAnchorEl(event.currentTarget);
  const handleClose = () => setOrgsAnchorEl(null);
  const handleMenu = event => setAccountAnchorEl(event.currentTarget);
  const handleAccountClose = () => setAccountAnchorEl(null);
  const handleLogOut = () => {
    handleAccountClose();
    sessionStorage.clear();
  };

  const handleCamChange = event => {
    window.localStorage.setItem(
      "cameraState.selectedDeviceId",
      event.target.value
    );

    let defaultOrgId = orgId;
    // Use currently selected org id if params is not available
    if (typeof orgId === "undefined") {
      defaultOrgId = selectedOrg?.id;
    }
    //let hooks manage side-effects based on requested URL
    let reviewer = "";
    if (defaultOrgId) {
      if (reviewerMode === "REVIEWER-SESSION") {
        reviewer = "session-events";
      } else if (reviewerMode === "REVIEWER-LABELLER") {
        reviewer = "labeller";
      } else {
        reviewer = "events";
      }
      history.push({
        pathname: `/${reviewer}/${defaultOrgId}/${event.target.value}`, // To
        state: {
          from: {
            pathname: history?.location?.pathname, // from
            deviceId: deviceId // previous deviceId
          }
        }
      });
    } else {
      // if both are undefined then redirect to events
      history.push(`/${reviewer}`);
    }
  };

  const handleOrgChange = org => {
    setSelectedOrg(org);
    dispatch({ type: "SET_SELECTED_CAMERA", deviceId: null });
  };

  return (
    <AppBar position="static" className={classes.bar}>
      <Toolbar>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            marginLeft: "auto",
            height: "80%",
            width: "220px",
            padding: "0 0 20px 0"
          }}
        >
          {!sessionReviewerRoutes.includes(pageBaseUrl) && (
            <>
              {organizations.length > 0 && (
                <>
                  <Button
                    onClick={handleOrgsClick}
                    endIcon={<ArrowDropDownIcon />}
                    className={classes.select}
                  >
                    {selectedOrg?.orgname ?? "Select Org"}
                  </Button>
                  <Popover
                    anchorEl={orgsAnchorEl}
                    anchorOrigin={{
                      horizontal: "center",
                      vertical: "bottom"
                    }}
                    open={Boolean(orgsAnchorEl)}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        width: 250,
                        maxHeight: "80vh"
                      }
                    }}
                  >
                    <OrganizationSelect onSelect={handleOrgChange} />
                  </Popover>
                </>
              )}
              {cameras && (
                <FormControl variant="standard">
                  <Select
                    variant="standard"
                    value={selectedCamera?.deviceId ?? ""}
                    onChange={handleCamChange}
                    className={classes.select}
                    data-testid="camera-select"
                  >
                    {cameras.map(cam => (
                      <MenuItem
                        key={cam.deviceId}
                        value={cam.deviceId}
                        className={classes.fontSize14}
                      >
                        {cam.name || cam.deviceId}
                        {cam.quantification && " [Q]"}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </>
          )}
        </div>
        <IconButton
          aria-label="account"
          onClick={handleMenu}
          size="large"
          sx={{ margin: "0 0 16px 0" }}
        >
          <AccountCircleIcon />
        </IconButton>
        <Menu
          anchorEl={accountAnchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={Boolean(accountAnchorEl)}
          onClose={handleAccountClose}
          getContentAnchorEl={null}
        >
          <MenuItem
            component={NavLink}
            to={"/logout"}
            onClick={handleLogOut}
            style={{
              fontSize: "1rem"
            }}
          >
            <ExitToApp
              style={{
                marginRight: "2px"
              }}
            />
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  reviewerMode: PropTypes.string
};

export default Header;
