import { makeStyles } from "@kuva/ui-components";

export const useAlarmListItemStyles = makeStyles()(
  (
    theme,
    {
      alarm,
      hasQuantification,
      hasValidDistance,
      selectedAlarm,
      isSourceValidForQuantification
    }
  ) => {
    return {
      listItem: {
        backgroundColor: selectedAlarm?.id === alarm.id ? "#333" : "unset"
      },
      poiLabel: {
        float: "right",
        color: "#2EC2CC"
      },
      primaryTypography: {
        fontSize: 14,
        color:
          selectedAlarm?.id === alarm.id
            ? "#FFD700"
            : hasQuantification
            ? theme.palette.custom.green[400]
            : (hasValidDistance || isSourceValidForQuantification) &&
              !hasQuantification
            ? theme.palette.custom.red[400]
            : theme.palette.common.white
      }
    };
  }
);
