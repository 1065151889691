import DeleteIcon from "@mui/icons-material/DeleteForever";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { GlobalStyle } from "GlobalStyle";
import PropTypes from "prop-types";
import { shallowEqual, useSelector } from "react-redux";

import { Autocomplete, Button, TextField } from "@kuva/ui-components";

import { selectDistanceSegmentsState } from "~/common/selectors/distance-segments-selector";
import { leakSourceTags } from "~/constants/reviewer-lookups";

import { useDistanceTableStyles } from "./styles";
export default function DistanceTable({ formikProps }) {
  const { classes: globalStyles } = GlobalStyle();
  const { classes } = useDistanceTableStyles();

  const poiDistanceSegmentState = useSelector(
    selectDistanceSegmentsState,
    shallowEqual
  );

  const { loading } = poiDistanceSegmentState;

  const {
    dirty,
    errors,
    handleChange,
    handleSubmit,
    isValid,
    setFieldTouched,
    setFieldValue,
    setValues,
    touched,
    values
  } = formikProps;

  const handleRowClick = segmentId => {
    const newSegments = values.segments.map(segment => ({
      ...segment,
      isSelected: segment.segmentId === segmentId
    }));
    setValues({ ...values, segments: newSegments });
  };

  const handleDeleteSegment = (event, segmentId) => {
    event.stopPropagation();
    const newSegments = values.segments.filter(
      segment => segment.segmentId !== segmentId
    );

    setValues({ ...values, segments: newSegments });
  };

  return (
    <div className={classes.root}>
      <Typography className={globalStyles.font14}>Distance Segments</Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          className={classes.table}
          aria-label="segment table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Source Tag</TableCell>
              <TableCell>Distance (Meters)</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values.segments.map(
              ({ segmentId, name, sourceTag, distance, isSelected }, index) => (
                <TableRow
                  key={segmentId}
                  onClick={() => handleRowClick(segmentId)}
                  className={isSelected ? classes.selectedRow : ""}
                >
                  <TableCell>
                    <TextField
                      name={`segments[${index}].name`}
                      variant="outlined"
                      size="small"
                      value={name}
                      autoFocus={isSelected}
                      onBlur={() =>
                        setFieldTouched(`segments[${index}].name`, true)
                      }
                      onChange={handleChange}
                      error={
                        touched?.segments?.[index]?.name &&
                        errors?.segments?.[index]?.name
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      name={`segments[${index}].sourceTag`}
                      label=""
                      variant="outlined"
                      options={leakSourceTags}
                      value={sourceTag}
                      onBlur={() =>
                        setFieldTouched(`segments[${index}].sourceTag`, true)
                      }
                      onChange={(event, value) => {
                        event.stopPropagation();
                        setFieldValue(`segments[${index}].sourceTag`, value);
                      }}
                      error={
                        touched?.segments?.[index]?.sourceTag &&
                        errors?.segments?.[index]?.sourceTag
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name={`segments[${index}].distance`}
                      className={classes.distanceField}
                      placeholder="m"
                      variant="outlined"
                      size="small"
                      value={distance}
                      type="number"
                      onBlur={() =>
                        setFieldTouched(`segments[${index}].distance`, true)
                      }
                      onChange={handleChange}
                      error={
                        touched?.segments?.[index]?.distance &&
                        errors?.segments?.[index]?.distance
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <DeleteIcon
                      className={classes.deleteIcon}
                      onClick={event => handleDeleteSegment(event, segmentId)}
                    />
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        className={classes.submitButton}
        variant="outlined"
        color="green"
        onClick={handleSubmit}
        disabled={!isValid || !dirty || loading}
        loading={loading}
      >
        Save Changes
      </Button>
    </div>
  );
}

DistanceTable.propTypes = {
  formikProps: PropTypes.shape({
    dirty: PropTypes.bool,
    errors: PropTypes.object,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    isValid: PropTypes.bool,
    resetForm: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    setValues: PropTypes.func,
    touched: PropTypes.object,
    values: PropTypes.object
  })
};
