import { cameraSelectionModes } from "~/constants/reviewerSessions";

export const initialState = {
  cameraState: {
    cameras: [],
    selectedDeviceId: window.localStorage.getItem(
      "cameraState.selectedDeviceId"
    )
      ? window.localStorage.getItem("cameraState.selectedDeviceId")
      : "",
    reported: []
  },
  alarmState: {
    pagination: {},
    alarms: [],
    alarmsByMonth: [],
    selected: undefined,
    poiList: [],
    selectedPois: [],
    isEditing: false,
    loading: false
  },
  shiftState: {
    isLoading: false,
    isStarted: false,
    shifts: [],
    current: undefined,
    error: undefined
  },
  sessionState: {
    isLoading: false,
    selectedCameras: [],
    selectedDuration: "1 Hour",
    selectedDate: new Date(),
    selectedDateRange: [new Date(), new Date()],
    selectedTime: undefined,
    selectedHourlyTimeslots: [],
    selectedDeviceHourlySessions: [],
    selectedDeviceScresPerHour: {},
    selectedCameraList: {},
    selectionMode: cameraSelectionModes.CAMERA_SELECTION,
    isSelectedAllHourlyTimeslots: false,
    cameraLists: [],
    events: [],
    sessions: [],
    openHourlyDialog: false,
    sessionsWithScres: [],
    activeHourlySessions: [],
    error: undefined
  },
  applicationSettings: {
    selectedResultsContainer: window.localStorage.getItem(
      "applicationSettings.selectedResultsContainer"
    )
      ? window.localStorage.getItem(
          "applicationSettings.selectedResultsContainer"
        )
      : "scanresult",
    showNonDetection:
      window.localStorage.getItem("applicationSettings.showNonDetection") ===
      "true",
    displayUtc:
      window.localStorage.getItem("applicationSettings.displayUtc") === "true",
    isImperialUnits:
      window.localStorage.getItem("applicationSettings.isImperialUnits") ===
      "true"
  }
};
